
import { AuthorsAPI } from '@/api'
import { Card } from '@/components/card'
import {
  AuthorFacets,
  CheckboxStage, convertSectionsToFacetsString, FacetGroup, FacetItem, Facets, FacetsValue, fetchFacets,
} from '@/components/facets'
import { trackAuthorsSearchCounter5 } from '@/counter5-tracker'
import { emptyResults, Results, SearchParams } from '@/global-types'
import { Component, VModel, Vue, Watch } from 'vue-property-decorator'
import ResultsComponent from './ResultsComponent.vue'
import { Author } from './types'
import { updateRouter } from './utils'
import { LastSearchTypeOfItem, RESET_LAST_SEARCH_STATE, UPDATE_LAST_SEARCH_ERROR_MESSAGE, UPDATE_LAST_SEARCH_HAS_ERRORS, UPDATE_LAST_SEARCH_NOT_MATCHING, UPDATE_LAST_SEARCH_TYPE_OF_ITEM } from '@/store'
import { AxiosError } from 'axios'
import { ErrorMessage } from '@/api/types'

@Component({
  components: {
    ResultsComponent,
    Card,
    Facets,
  },
})
export default class AuthorsResults extends Vue {
  @VModel() searchParams!: SearchParams

  //
  // REACTIVE PROPERTIES
  //
  authorsResults: Results<Author> = emptyResults
  facetGroups: FacetGroup[] = []
  tempMarkedFacets: Map<string, Set<FacetItem>> = new Map<string, Set<FacetItem>>()

  loadingAuthors = false
  loadingFacets = false
  // notMatchingResults = false
  filtersModalOpened = false
  filtersOpened = false
  concatResults = false
  loadFacets = true
  pageSize = 20
  pageSizeKey = 'authorPageSize'

  /* -------------------------------------------------------------------------- */
  /*                                  WATCHERS                                  */
  /* -------------------------------------------------------------------------- */
  @Watch('searchParams', { deep: true, immediate: true })
  onSearchParamsChanged(newParams, oldParams) {
    // scroll window to the top
    window.scrollTo(0, 0)

    const counter5TrackingNeeded = this.checkIfCounter5TrackingNeeded(newParams, oldParams)

    this.fetchData(counter5TrackingNeeded)
  }

  /* -------------------------------------------------------------------------- */
  /*                             COMPUTED PROPERTIES                            */
  /* -------------------------------------------------------------------------- */
  get facetsFromURL() {
    return this.searchParams.facets ? this.searchParams.facets : ''
  }

  get notMatchingResults() {
    return this.$store.getters.lastSearchNotMatchingResults
  }

  set notMatchingResults(value: boolean) {
    this.$store.commit(UPDATE_LAST_SEARCH_NOT_MATCHING, value)
  }

  get showError() {
    return this.$store.getters.lastSearchHasErrors
  }

  set showError(value: boolean) {
    this.$store.commit(UPDATE_LAST_SEARCH_HAS_ERRORS, value)
  }

  get errorMessage() {
    return this.$store.getters.lastSearchErrorMessage
  }

  set errorMessage(value: string) {
    this.$store.commit(UPDATE_LAST_SEARCH_ERROR_MESSAGE, value)
  }

  /* -------------------------------------------------------------------------- */
  /*                               LIFECYCLE HOOKS                              */
  /* -------------------------------------------------------------------------- */
  mounted() {
    this.$store.dispatch(RESET_LAST_SEARCH_STATE)
    this.$store.commit(UPDATE_LAST_SEARCH_TYPE_OF_ITEM, LastSearchTypeOfItem.AUTHOR)

    this.pageSize = this.searchParams.pageSize
    if (localStorage.authorsSearchComponentFiltersOpened) {
      this.filtersOpened = JSON.parse(localStorage.authorsSearchComponentFiltersOpened)
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  checkIfCounter5TrackingNeeded(newParams: SearchParams, oldParams: SearchParams) {
    return (
      window.routerInfo.from.path === '/' ||
        window.routerInfo.from.path === window.routerInfo.to.path ||
        window.routerInfo.to.name === window.lastClickedRouterLinkInfo?.name
    ) &&
      (
        oldParams === undefined ||
        newParams.query !== oldParams.query ||
        newParams.facets !== oldParams.facets
      )
  }

  toggleFilters() {
    this.filtersOpened = !this.filtersOpened

    localStorage.authorsSearchComponentFiltersOpened = this.filtersOpened
  }

  toggleFiltersModal() {
    this.filtersModalOpened = !this.filtersModalOpened
  }

  ifQueryNonEmpty(run: () => void) {
    if (this.searchParams.query.trim().length > 0) {
      run()
    }
  }

  fetchData(trackCounter5 = false) {
    this.ifQueryNonEmpty(async() => {
      this.notMatchingResults = false

      if (this.loadFacets) {
        this.fetchFacetsData()
      }

      await this.fetchAuthorsData(trackCounter5)
    })
  }

  private primClassFacetToFacetItem(pc: FacetsValue): FacetItem {
    return {
      value: pc.id,
      label: `<div class="d-flex"><div style="font-family: monospace;">${pc.id.toUpperCase()}</div><div>&nbsp;-&nbsp;</div><div>${pc.value}</div></div>`,
      count: pc.total,
      stage: CheckboxStage.Unselected,
    }
  }

  private async fetchAuthorsData(trackCounter5) {
    this.loadingAuthors = true
    this.showError = false

    try {
      let authorsResults = await AuthorsAPI.searchQuery(
          `${this.searchParams.query} ${this.searchParams.facets}`.trim(),
          this.searchParams.pageNumber,
          this.searchParams.pageSize,
          this.searchParams.sortBy ? this.searchParams.sortBy : '',
      )

      // concat results if "MORE" button is used instead of pagination nav
      if (this.concatResults) {
        authorsResults = {
          results: [...this.authorsResults.results, ...authorsResults.results],
          total: this.authorsResults.total + authorsResults.total,
        }
      }

      if (authorsResults.total === 0) {
        this.notMatchingResults = true
      }

      if (
        authorsResults.results.length === 1 &&
        this.searchParams.pageNumber === 1
      ) {
        this.$router.replace({
          name: 'AuthorPage',
          query: {
            authorId: authorsResults.results[0].id.toString(),
          },
        })
      } else {
        this.authorsResults = authorsResults
      }

      if (trackCounter5) trackAuthorsSearchCounter5()
    } catch (e) {
      if (e instanceof AxiosError) {
        try {
          this.errorMessage = ((e as AxiosError).response?.data as ErrorMessage).message
        } catch (e2) {
          this.errorMessage = (e as AxiosError).response?.data as string
        }
      }

      this.showError = true
    }

    this.loadingAuthors = false
  }

  private async fetchFacetsData() {
    this.loadingFacets = true

    this.facetGroups = []
    this.facetGroups = await fetchFacets(
      this.searchParams,
      AuthorsAPI.getFacets,
      this.convertToFacetGroups,
    )

    this.loadingFacets = false
  }

  private convertToFacetGroups(results: AuthorFacets): FacetGroup[] {
    const primaryClassificationsGroup = {
      id: 'pc',
      title: 'Primary Classification',
      items: results.primaryClassifications
        ? results.primaryClassifications.map(pc => ({
          value: pc.id,
          label: `<span style="font-family: monospace;">${pc.id.toUpperCase()}</span> - ${pc.value}`,
          count: pc.total,
          stage: CheckboxStage.Unselected,
        }))
        : [],
      searchable: true,
      operations: {
        filter: async(value: string) => {
          // const facets = this.searchParams.facets ? this.searchParams.facets.replace(/\sAND\s/g, ' ') : ''
          const results = await AuthorsAPI.getPrimaryClassificationsFacet(
            this.searchParams.query.trim(),
            value,
          )

          return results.map(this.primClassFacetToFacetItem)
        },
        sort: (items: FacetItem[]) => {
          return items.sort((a, b) => {
            return b.count - a.count
          })
        },
      },
    }

    return [
      primaryClassificationsGroup,
    ]
  }

  updateURLParams(searchParams: SearchParams) {
    updateRouter(this.$router)(searchParams)
  }

  updateCurrentPage(pageNumber: number) {
    this.concatResults = false
    this.updateURLParams({ ...this.searchParams, pageNumber })
  }

  updateInternalPageSize(pageSize: number) {
    this.pageSize = pageSize
    this.searchParams.pageNumber = 1
    this.searchParams.pageSize = pageSize
    this.updateURLParams({ ...this.searchParams, pageSize })
    localStorage.setItem(this.pageSizeKey, pageSize.toString())
  }

  updateSortBy(sortBy: string) {
    this.updateURLParams({ ...this.searchParams, sortBy })
  }

  updateFacets(facets: string) {
    this.updateURLParams({ ...this.searchParams, facets })
  }

  getMoreResults() {
    this.concatResults = true
    this.updateURLParams({ ...this.searchParams, pageNumber: this.searchParams.pageNumber + 1 })
  }

  onApplyFacetsHandler(facetGroups: FacetGroup[]) {
    const facets = convertSectionsToFacetsString(facetGroups)
    if (this.facetsFromURL !== facets) {
      this.tempMarkedFacets.clear()
      const linked = facetGroups
        .filter(g => g.linkedANDSearchSelected)
        .map(g => (g.id))
      this.updateURLParams({ ...this.searchParams, facets, pageNumber: 1, linkedANDSearchFieldsActive: linked })
    }
  }

  onApplyGroupHandler(group: FacetGroup) {
    const index = this.facetGroups.findIndex(g => g.id === group.id)
    this.facetGroups.splice(index, 1, group)

    const facets = convertSectionsToFacetsString(this.facetGroups)
    if (this.facetsFromURL !== facets) {
      const linked = this.facetGroups
        .filter(g => g.linkedANDSearchSelected)
        .map(g => (g.id))
      this.updateURLParams({ ...this.searchParams, facets, pageNumber: 1, linkedANDSearchFieldsActive: linked })
    }
  }

  onClearFacetsHandler(groups: FacetGroup[]) {
    const facets = convertSectionsToFacetsString(groups)
    this.updateURLParams({ ...this.searchParams, facets, linkedANDSearchFieldsActive: undefined })
  }

  onClearGroupHandler(group: FacetGroup) {
    const index = this.facetGroups.findIndex(g => g.id === group.id)

    if (index === -1) return

    this.facetGroups.splice(index, 1, group)

    const facets = convertSectionsToFacetsString(this.facetGroups)
    if (this.facetsFromURL !== facets) {
      this.updateURLParams({ ...this.searchParams, facets, linkedANDSearchFieldsActive: undefined })
    }
  }

  onClearFilters() {
    this.updateURLParams({ ...this.searchParams, facets: '' })
  }
}
